<template>
    <div>
      <UserDetailItemEditCard confirmation-message="Contact informatie werd succesvol gewijzigd"
                          title="Bewerk contact informatie"
                          :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                          update-store-action="usersModule/updateUserContactInformation"
                          :update-store-action-payload="{userId: this.userId, contactInformation: this.userContactInformation}"
                          :userId="userId" :promises.sync="promises">
        <template v-slot:form-fields>
            <v-row>
                <v-col cols="12">
                    <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-card-account-mail</v-icon> Contact informatie</v-subheader>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field persistent-placeholder disabled label="Werk email" type="email" v-model="userContactInformation.email"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field persistent-placeholder label="Persoonlijke email" type="email" v-model="userContactInformation.personalEmail" :rules="getEmailRules(false)"/>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field persistent-placeholder label="Telefoon" type="phone" v-model="userContactInformation.phoneNumber"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-map-marker</v-icon> Adres</v-subheader>
                    <AddressInput v-model="userContactInformation.address"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-subheader class="text-h5 pl-0"><v-icon class="pr-2">mdi-account-alert</v-icon> Contact voor noodgevallen</v-subheader>
                    <EditUserContacts v-for="(contact, index) in userContactInformation.contacts" v-model="userContactInformation.contacts[index]" v-bind:key="index"/>
                </v-col>
            </v-row>
        </template>
      </UserDetailItemEditCard>
    </div>
  </template>
  
<script>
import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"
import AddressInput from "@/components/shared/fields/AddressInput.vue";
import EditUserContacts from "@/components/shared/fields/EditUserContacts.vue";
import {getEmailRules} from "@/shared/utils/inputRulesUtils";

export default {
    name: "UserDetailEditContactInformation",
    props: {
        userId: {
        type: String,
        required: true
        }
    },
    methods: {
        getEmailRules
    },
    data() {
        return {
            dataLoading: true,
            userContactInformation: {
                address: {}
            },
            dataFetchError: false,
            routeNames: routeNames,
            promises: [
                this.$store.dispatch('usersModule/fetchUserContactInformation', this.userId).then((result) => {
                    this.userContactInformation = result
                    if (!this.userContactInformation.address) {
                        this.userContactInformation.address = {}
                    }
                    if (!this.userContactInformation.contacts) {
                        this.userContactInformation.contacts = [{primaryContact: true}]
                    }
                })
            ]
        }
    },
    components: {
        UserDetailItemEditCard,
        AddressInput,
        EditUserContacts
    },
}

</script>