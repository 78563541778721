<template>
    <v-row>
        <v-col cols="12" sm="6">
            <v-text-field  persistent-placeholder label="Voornaam" type="text"  v-model="modelValue.firstName"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field  persistent-placeholder label="Achternaam" type="text"  v-model="modelValue.lastName"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field  persistent-placeholder label="Telefoon" type="text"  v-model="modelValue.phoneNumber"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field  persistent-placeholder label="Email" type="email"  v-model="modelValue.email" :rules="getEmailRules(false)"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
            <v-text-field  persistent-placeholder label="Relatie" type="text"  v-model="modelValue.relation"></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import {getEmailRules} from "@/shared/utils/inputRulesUtils";

export default {
    name: "EditContacts",
    props: ['value'],
    emits: ['update:value'],
    methods: {
        getEmailRules
    },
    computed: {
        modelValue: {
            get() {
                return this['value'] || {}
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    }
}

</script>